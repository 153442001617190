<template>
  <div class="BetaWelcomePage">
    <div class="BWPContainer">
      <Navbar :userIsAnonymous="true" />

      <div class="FieldsForRegistration" v-if="!ErrorWindow">
        <span class="PageName">Регистрация по ссылке партнера</span>

        <div class="InputWrapper">
          <span class="Label">Имя</span>
          <input type="text" v-model="UserData.firstname" placeholder="Иван" />
        </div>
        <div class="InputWrapper">
          <span class="Label">Фамилия</span>
          <input
            type="text"
            v-model="UserData.lastname"
            placeholder="Иванович"
          />
        </div>
        <div class="InputWrapper">
          <span class="Label">Отчество</span>
          <input
            type="text"
            v-model="UserData.patronymic"
            placeholder="Иванов"
          />
        </div>
        <div class="InputWrapper">
          <span class="Label">Наименование организации</span>
          <input type="text" v-model="OrgData.name" placeholder="Форус" />
        </div>
        <div class="InputWrapper">
          <span class="Label">ИНН организации</span>
          <input type="text" v-model="OrgData.inn" placeholder="" />
        </div>
        <div class="InputWrapper">
          <span class="Label">Отрасль</span>
          <input
            type="text"
            v-model="OrgData.industry"
            placeholder="Например, IT"
          />
        </div>
        <div class="InputWrapper">
          <span class="Label">Вид деятельности</span>
          <input
            type="text"
            v-model="OrgData.activityType"
            placeholder="Пример: Разработка ПО, Консалтинг и т.д."
          />
        </div>
        <div class="InputWrapper">
          <span class="Label">Электронная почта</span>
          <input
            type="text"
            v-model="UserData.email"
            placeholder="admin@example.com"
          />
        </div>
        <div class="InputWrapper">
          <span class="Label">Номер телефона</span>
          <input type="text" v-model="UserData.tel" placeholder="" />
        </div>

        <GradientButton
          @click="UseRefferalLink"
          class="ButtonRegistration"
          ButtonText="Зарегистрироваться"
        />
      </div>
    </div>

    <div class="ErrorWindow" v-if="ErrorWindow">
      <div class="ModalText">
        <span class="ErrorMessage">{{ ErrorText }}</span>
        <router-link :to="ErrorButtonRedirect">
          <GradientButton class="ButtonWidth" :ButtonText="ErrorButtonText" />
        </router-link>
      </div>
    </div>

    <div class="LoadingWindow" v-if="LoadingScreen">
      <div class="ModalText">
        <span class="ErrorMessage loading">Создание компании, ожидайте...</span>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar/NavbarCombined.vue";
import GradientButton from "@/components/CustomElements/ButtonGradient.vue";
import ButtonGradientBorder from "@/components/CustomElements/ButtonGradientBorder.vue";

import { mapGetters, mapMutations } from "vuex";
import { useRoute } from "vue-router";

import { codeIsValid } from "@/api/unique_link.js";
import { referralRegistration } from "@/api/company_requests.js";

export default {
  components: { Navbar, GradientButton, ButtonGradientBorder },
  name: "UniqueLinkRegistration",
  data() {
    return {
      ErrorText: null,
      ErrorWindow: false,
      ErrorButtonRedirect: "/",
      ErrorButtonText: "Вернуться на главную",

      OrgData: {
        name: "",
        inn: "",
        partner_code: null,
        type: "Организация",
        industry: "",
        activityType: "",
        partner_id: null,
        reg_source: "Реферальная ссылка партнера",
        region: null,
      },
      UserData: {
        firstname: "",
        lastname: "",
        patronymic: "",
        tel: "",
        email: "",
      },
      LinkRegistration: {
        code: null,
      },

      LoadingScreen: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/isLoggedIn",
    }),
  },
  async created() {
    const route = useRoute();

    if (this?.user?.id != null) {
      this.ErrorText = "Вы авторизированны и не можете быть зарегистрированны";
      this.ErrorWindow = true;
      return 0;
    }

    if (route?.query?.code == null) {
      this.ErrorText = "Неверный формат ссылки";
      this.ErrorWindow = true;
      return 0;
    }

    let checkCodeAvailable = null;
    try {
      checkCodeAvailable = await codeIsValid({ code: route.query?.code });
    } catch (e) {}

    if (checkCodeAvailable == null) {
      this.ErrorText = "Код недействителен";
      this.ErrorWindow = true;
      return 0;
    } else {
      this.LinkRegistration.code = checkCodeAvailable?.data?.code;

      if (
        checkCodeAvailable?.data?.linkCreator?.parent_company[0]?.id != null
      ) {
        this.OrgData.partner_id =
          checkCodeAvailable?.data?.linkCreator?.parent_company[0]?.id;
      }

      if (
        checkCodeAvailable?.data?.linkCreator?.parent_company[0]?.region != null
      ) {
        this.OrgData.region =
          checkCodeAvailable?.data?.linkCreator?.parent_company[0]?.region;
      }
    }
  },
  methods: {
    ...mapMutations("auth", ["SET_USER"]),
    async UseRefferalLink() {
      this.LoadingScreen = true;

      let res = await referralRegistration({
        company_data: this.OrgData,
        agent_data: this.UserData,
        link_data: this.LinkRegistration,
      });

      if (res.data.status == "fail") {
        this.ErrorText = res.data.message;
        this.ErrorWindow = true;
        this.LoadingScreen = false;
      } else {
        this.ErrorText =
          "Ваша организация успешно зарегистрирована в Октагоне. \nДанные для входа отправлены вам на почту";
        this.ErrorButtonText = "Войти в профиль";
        this.ErrorButtonRedirect = "/auth/signup-login#pills-register";
        this.ErrorWindow = true;
      }
    },
  },
};
</script>

<style scoped>
.ButtonRegistration {
  position: relative;

  margin-top: 30px;

  width: 100%;
}

.FieldsForRegistration .PageName {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  color: #e8e7ec;

  margin-left: auto;
  margin-right: auto;

  margin-bottom: 60px;

  font-size: 31px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
  letter-spacing: -0.28px;
}
.FieldsForRegistration .InputWrapper {
  position: relative;

  width: auto;
  height: fit-content;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 4px;
}

.FieldsForRegistration .InputWrapper > .Label {
  color: #e8e7ec;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
  letter-spacing: -0.28px;
}

.InputWrapper > input,
.InputWrapper > textarea {
  position: relative;

  width: 100%;
  height: 100%;

  padding: 14px 12px;

  border-radius: 4px;
  border: 1px solid #413e74;
  background: linear-gradient(
    269deg,
    rgba(41, 37, 88, 0.54) 4.06%,
    rgba(38, 35, 83, 0.87) 42.35%,
    rgba(40, 38, 89, 0.5) 99.53%
  );

  color: #c8c3de;
  text-overflow: ellipsis;

  font-family: "Montserrat";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 155%; /* 23.25px */
}

.InputWrapper > input:focus-visible,
.InputWrapper > textarea:focus-visible {
  outline: 2px solid rgb(130, 111, 238) !important;
  border-radius: 3px;
}

.InputWrapper > textarea {
  min-height: 96px;
}

.ButtonWidth {
  min-width: 200px;
}

.ErrorWindow {
  position: fixed;
  display: flex;

  width: 100%;
  height: 100vh;

  z-index: 5000;

  background-color: rgba(0, 0, 0, 0.65);
  backdrop-filter: blur(20px);

  overflow: hidden;
}
.LoadingWindow {
  position: fixed;
  display: flex;

  width: 100%;
  height: 100vh;

  z-index: 4000;

  background-color: rgba(0, 0, 0, 0.65);
  backdrop-filter: blur(20px);

  overflow: hidden;
}

.ErrorWindow > .ModalText > *,
.LoadingWindow > .ModalText > * {
  margin-left: auto;
  margin-right: auto;
}

.ErrorWindow > .ModalText,
.LoadingWindow > .ModalText {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: fit-content;
  height: fit-content;

  gap: 30px;

  margin: auto;
}

.ErrorWindow > .ModalText > .ErrorMessage,
.LoadingWindow > .ModalText > .ErrorMessage {
  position: relative;

  color: white;

  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
  letter-spacing: -0.28px;
}

.BetaWelcomePage {
  position: relative;

  display: flex;

  width: 100%;
  height: 100vh;

  background-image: radial-gradient(
    123.22% 129.67% at 100.89% -5.6%,
    #201d47 0%,
    #17153a 100%
  ) !important;
  background-position: 50% 50% !important;
  background-attachment: fixed !important;
  background-repeat: no-repeat;
  background-size: auto 120% !important;
}
.BetaWelcomePage > .BWPContainer {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 30px;

  padding: 50px 120px 32px 120px;

  width: 100%;
  height: 100vh;
}

.BetaWelcomePage > .BWPContainer > .FieldsForRegistration {
  position: relative;

  display: flex;
  padding: 0% 10px 30px 10px;

  flex-direction: column;
  justify-content: flex-start;
  gap: 15px;

  width: 650px;
  height: 100%;

  margin-left: auto;
  margin-right: auto;

  flex-basis: auto;
  overflow: auto;
  flex-grow: 1;
  flex-shrink: 1;
}

.loading {
  font-weight: bold;
  display: inline-block;
  font-family: monospace;
  font-size: 30px;
  clip-path: inset(0 0.9ch 0 0);
  animation: l 1s steps(4) infinite;
}

@keyframes l {
  to {
    clip-path: inset(0 -0.45ch 0 0);
  }
}
</style>
