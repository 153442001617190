import request from "@/api/config.js";

export function createCode(data) {
  return request({
    url: `uniqlink/create`,
    method: "post",
    data,
  });
}
export function deleteCode(code) {
  return request({
    url: `uniqlink/deleteCode/${code}`,
    method: "post",
  });
}

export function codeIsValid(data) {
  return request({
    url: `uniqlink/codeIsValid`,
    method: "post",
    data,
  });
}
export function userActivateCode(data) {
  return request({
    url: `uniqlink/userActivateCode`,
    method: "post",
    data,
  });
}

export function getAllUsedLinks(user_id) {
  return request({
    url: `uniqlink/user/${user_id}/getAllUsedLinks`,
    method: "get",
  });
}
export function getAllActiveLinks(user_id) {
  return request({
    url: `uniqlink/user/${user_id}/getAllActiveLinks`,
    method: "get",
  });
}
